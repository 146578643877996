<template>
  <div>
    <input
      v-on:change="doUpload($event.target)"
      :multiple="multiple"
      style="display: none"
      type="file"
      ref="file"
    />
    <slot :selectFiles="selectFiles"></slot>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import _ from "lodash";

export default {
  computed: {
    ...mapGetters(["clientId"]),
  },
  data: function () {
    return {
      running: false,
      internalValue: [],
      uploadQueue: [],
    };
  },
  methods: {
    addUpload: function (f) {
      this.koreUpload(`/v1/uploads/${this.clientId}`, f, (completed, i) => {
        const index = _.findIndex(this.internalValue, { nome: i.name });
        const file = _.find(this.internalValue, { nome: i.name });
        if (i.error) {
          file.error = i.error;
          file.uploading = false;
        } else if (completed) {
          file.percent = 100;
          file.uploading = false;
          file.url = i.url;
        } else {
          file.percent = i.percent > 99 ? 99 : i.percent;
        }

        this.internalValue.splice(index, 1, file);
        this.$emit("input", this.internalValue);
        this.$emit("changed", this.internalValue);
        this.$forceUpdate();

        if (completed) {
          this.running = false;
          this.startQueue();
        }
      });
    },
    doUpload: function (el) {
      this.internalValue = this.multiple ? this.value : [];
      for (let f in el.files) {
        let file = el.files[f];

        if (typeof file == "object") {
          const { length } = this.internalValue;
          const newUpload = {
            nome: el.files[f].name,
            uploading: true,
            percent: 0,
            url: "",
          };

          this.internalValue.splice(length, 0, newUpload);
          this.$emit("input", this.internalValue);
          this.$emit("changed", this.internalValue);

          this.uploadQueue.push(file);
        }
      }
      el.value = "";
      this.startQueue();
    },
    selectFiles: function () {
      this.$refs["file"].click();
    },
    startQueue: function () {
      if (this.running === false) {
        this.running = true;
        let first = _.first(this.uploadQueue);

        if (first) {
          this.addUpload(first);
          this.uploadQueue.shift();
        } else {
          this.running = false;
        }
      }
    },
  },
  props: { multiple: { default: true }, value: { default: () => [] } },
};
</script>
